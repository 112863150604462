import { useState, useEffect, useRef } from "react";
import Rete from "rete";
import { createRoot } from "react-dom/client";
import ReactRenderPlugin from "rete-react-render-plugin";
import ConnectionPlugin from "rete-connection-plugin";
import AreaPlugin from "rete-area-plugin";
import Context from "efficy-rete-context-menu-plugin";
import { MyNode } from "./MyNode";
import { INPUT_ID_CONSTANTS } from "../constants";
import { InputControl, InputControlSelect, socket } from "./Control";

class NodeComponent extends Rete.Component {
  constructor() {
    super("Node");
    this.data.component = MyNode; // optional
  }

  builder(node) {
    // var output = new Rete.Output("nodeOutput", "", socket);
    var output1 = new Rete.Output("nodeOutput1", "condition 1", socket);
    var output2 = new Rete.Output("nodeOutput2", "condition 2", socket);

    // INPUTS ------------
    var label = new InputControl(this.editor, INPUT_ID_CONSTANTS.label, node);
    var type = new InputControlSelect(this.editor, INPUT_ID_CONSTANTS.type, node);
    var eventName = new InputControl(this.editor, INPUT_ID_CONSTANTS.eventName, node);

    // -----------CONDITION 1--------------------
    var fact1 = new InputControl(this.editor, INPUT_ID_CONSTANTS.fact1, node, 1);
    var operator1 = new InputControlSelect(this.editor, INPUT_ID_CONSTANTS.operator1, node);
    var value1 = new InputControl(this.editor, INPUT_ID_CONSTANTS.value1, node);
    var sum1 = new InputControl(this.editor, INPUT_ID_CONSTANTS.sum1, node);
    var count1 = new InputControl(this.editor, INPUT_ID_CONSTANTS.count1, node);
    // -----------CONDITION 2--------------------

    var operator2 = new InputControlSelect(this.editor, INPUT_ID_CONSTANTS.operator2, node);
    var fact2 = new InputControl(this.editor, INPUT_ID_CONSTANTS.fact2, node, 2);
    var value2 = new InputControl(this.editor, INPUT_ID_CONSTANTS.value2, node);
    var sum2 = new InputControl(this.editor, INPUT_ID_CONSTANTS.sum2, node);
    var count2 = new InputControl(this.editor, INPUT_ID_CONSTANTS.count2, node);

    var actionType = new InputControl(this.editor, INPUT_ID_CONSTANTS.actionType, node);
    var actionDetail = new InputControl(this.editor, INPUT_ID_CONSTANTS.actionDetail, node);

    // -----------DELAY--------------------
    var delayTime = new InputControl(this.editor, INPUT_ID_CONSTANTS.delayTime, node);
    // var delayUnit = new InputControlSelect(this.editor, INPUT_ID_CONSTANTS.delayUnit, node);

    return (
      node
        .addInput(new Rete.Input("nodeInput", "", socket, true)) // true for adding multiple input connections // addInput will add a input which have socket
        .addControl(type) // This is normal input
        .addControl(label)
        .addControl(eventName)
        .addControl(fact1)
        .addControl(value1)
        .addControl(operator1)
        .addControl(sum1)
        .addControl(count1)
        .addControl(fact2)
        .addControl(value2)
        .addControl(operator2)
        .addControl(sum2)
        .addControl(count2)
        .addControl(actionType)
        .addControl(actionDetail)
        .addControl(delayTime)
        // .addControl(delayUnit)
        // .addOutput(output) // output node
        .addOutput(output1)
        .addOutput(output2)
    );
  }
}

export async function createEditor(container) {
  var components = [new NodeComponent()];

  var editor = new Rete.NodeEditor("node-editor-rete@0.1.0", container);
  editor.use(ConnectionPlugin);
  editor.use(ReactRenderPlugin, { createRoot });

  editor.use(Context, {
    searchBar: false,
    delay: 100,
    allocate(component) {
      return [];
    },
  });

  var engine = new Rete.Engine("node-editor-rete@0.1.0");

  components.map((c) => {
    editor.register(c);
    engine.register(c);
  });

  editor.on("process nodecreated noderemoved connectioncreated connectionremoved", async (e) => {
    await engine.abort();
    await engine.process(editor?.toJSON());
  });

  editor.on("zoom", (e) => {
    let k = e.transform.k; // current zoom level
    if (e.zoom > k) {
      // e.zoom is new zoom level
      e.zoom = k + 0.01;
    } else if (e.zoom < k) {
      e.zoom = k - 0.01;
    }
    e.zoom = +e.zoom.toFixed(2);

    return e.source !== "dblclick"; // disable zoom by double click
  });

  editor.view.resize();
  editor.trigger("process");
  AreaPlugin.zoomAt(editor, editor.nodes);

  return editor;
}

export function useRete(json) {
  const [container, setContainer] = useState(null);
  const editorRef = useRef();

  useEffect(() => {
    if (container) {
      createEditor(container).then((value) => {
        editorRef.current = value;
        if (json) {
          editorRef.current.fromJSON(json);
        }
      });
    }
  }, [container]);

  useEffect(() => {
    return () => {
      if (editorRef.current) {
        editorRef.current.destroy();
      }
    };
  }, []);

  return [container, setContainer, editorRef];
}
