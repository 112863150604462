import Rete from "rete";
import { DELAY_TIME_OPTIONS, INPUT_ID_CONSTANTS, TYPE_CONSTANTS, TYPE_OPTIONS_CONSTANTS } from "../constants";

export class InputControl extends Rete.Control {
  static component = ({ id, onChange, value }) => (
    <div className="input-control">
      <label htmlFor={id}>{camelCaseToWords(id)}</label>
      <input
        id={id}
        type="text"
        value={!value || value === "0" ? "" : value}
        ref={(ref) => {
          ref && ref.addEventListener("pointerdown", (e) => e.stopPropagation());
        }}
        onChange={(e) => onChange(e.target.value)}
        placeholder={"Enter Text"}
      />
    </div>
  );

  constructor(emitter, key, node, condtionIndex) {
    super(key);
    this.emitter = emitter;
    this.key = key;
    this.component = InputControl.component;

    const initial = node.data[key] || 0;

    node.data[key] = initial;
    this.props = {
      id: this.key,
      condtionIndex,
      value: initial,
      onChange: (v) => {
        this.setValue(v);
        this.emitter.trigger("process");
        node.update();
      },
    };
  }

  setValue(val) {
    this.props.value = val;
    this.putData(this.key, val);
    this.update();
  }
}

export class InputControlSelect extends Rete.Control {
  static component = (props) => {
    const { id, onChange, value } = props;

    return (
      <div className="input-control">
        <label htmlFor={id}>{camelCaseToWords(id)}</label>
        <select
          id={id}
          type="text"
          value={!value || value === "0" ? "" : value}
          ref={(ref) => {
            ref && ref.addEventListener("pointerdown", (e) => e.stopPropagation());
          }}
          onChange={(e) => onChange(e.target.value)}>
          <RenderOptions type={id} />
        </select>
      </div>
    );
  };

  constructor(emitter, key, node) {
    super(key);
    this.emitter = emitter;
    this.key = key;
    this.component = InputControlSelect.component;

    const initial = node.data[key] || 0;

    node.data[key] = initial;
    this.props = {
      id: this.key,
      value: initial,
      onChange: (v) => {
        this.setValue(v);
        this.emitter.trigger("process");
        node.update();
      },
    };
  }

  setValue(val) {
    this.props.value = val;
    this.putData(this.key, val);
    this.update();
  }
}

const RenderOptions = ({ type }) => {
  if (type === "operator_1" || type === "operator_2")
    return (
      <>
        <option value="" disabled>
          Choose Option Here
        </option>
        <option value="equal">Equal</option>
        <option value="greaterThan">Greater Than</option>
        <option value="lessThan">Less Than</option>
        {/* <option value="count">Count</option>
        <option value="sum">Sum</option> */}
      </>
    );
  else if (type === "type")
    return (
      <>
        <option value="" disabled>
          Choose Option Here
        </option>
        <option value={TYPE_OPTIONS_CONSTANTS.action}>Action</option>
        <option value={TYPE_OPTIONS_CONSTANTS.process}>Process</option>
        <option value={TYPE_OPTIONS_CONSTANTS.delay}>Delay</option>
      </>
    );
  else if (type === "delayUnit")
    return (
      <>
        <option value="" disabled>
          Choose Time
        </option>
        <option value={DELAY_TIME_OPTIONS.hour}>Hour</option>
        <option value={DELAY_TIME_OPTIONS.minute}>Minute</option>
        <option value={DELAY_TIME_OPTIONS.second}>Second</option>
      </>
    );
};

function camelCaseToWords(_string) {
  if (!_string) return "";
  return _string.replace("_", " ").replace(/([A-Z]+)*([A-Z][a-z])/g, "$1 $2");
}

export const socket = new Rete.Socket("socket-circle"); // passing classname
