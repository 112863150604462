import { Node, Socket, Control } from "rete-react-render-plugin";
import { subscription } from "../service/subscription.service";

import React, { useEffect, useState } from "react";
import { INPUT_ID_CONSTANTS, TYPE_CONSTANTS, TYPE_OPTIONS_CONSTANTS } from "../constants";
import { useRef } from "react";
import { getAuthDetails } from "../service/helper.service";

const TABLE_HEAD = ["First Name", "Last Name", "Mobile"];

const findTypeKey = (list) => {
  if (list.some((it) => it.props.value === "action")) {
    return TYPE_CONSTANTS.action;
  } else if (list.some((it) => it.props.value === "process")) {
    return TYPE_CONSTANTS.process;
  } else if (list.some((it) => it.props.value === "delay")) {
    return TYPE_CONSTANTS.delay;
  } else return TYPE_CONSTANTS.none;
};

export class MyNode extends Node {
  constructor() {
    super();
    this.state = {
      showTable: false,
      isDragging: false,
      _id: null,
      nodeId: null,
    };
  }

  hideModal = () => {
    this.setState({
      ...this.state,
      showTable: false,
    });
  };

  toggleTableOnNodeClick = (node) => {
    let _id = localStorage.getItem("_id") && localStorage.getItem("_id") !== "undefined" ? JSON.parse(localStorage.getItem("_id")) : null;

    this.setState({
      ...this.state,
      showTable: _id ? !this.state.showTable : false,
      _id: _id,
      nodeId: node?.id,
    });
  };

  render() {
    const { node, bindSocket, bindControl } = this.props;
    const { outputs, controls, inputs, selected } = this.state;

    return (
      <>
        <div className={`node ${selected}`} style={{ background: "grey" }}>
          {/* <div className="title">{!node?.data?.label || node?.data?.label === "0" ? node.name : node?.data?.label}</div> */}
          <div className="title">
            Node
            <button
              className="show-table-btn"
              onClick={() => {
                this.toggleTableOnNodeClick(node);
              }}>
              Show User Table
            </button>
          </div>

          {node.data.type === TYPE_OPTIONS_CONSTANTS.action || node.data.type === TYPE_OPTIONS_CONSTANTS.delay
            ? outputs.map((output) =>
                output.name == "condition 1" ? (
                  <div className="output" key={output.key}>
                    {/* <div className="output-title">{output.name}</div> */}
                    <Socket type="output" socket={output.socket} io={output} innerRef={bindSocket} />
                  </div>
                ) : null
              )
            : null}

          <ControlComponent controls={controls} bindControl={bindControl} bindSocket={bindSocket} outputs={outputs} />

          {inputs.map((input) => (
            <div className="input" key={input.key}>
              <Socket type="input" socket={input.socket} io={input} innerRef={bindSocket} />
              {input.showControl() && <Control className="input-control" control={input.control} innerRef={bindControl} />}
            </div>
          ))}
        </div>

        {this.state.showTable ? <NodeUserTable {...this.state} hideModal={this.hideModal} /> : null}
      </>
    );
  }
}

const NodeUserTable = (props) => {
  const { _id, nodeId, hideModal, showTable } = props;

  const modalRef = useRef();

  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target) && !event.target.classList.contains("show-table-btn")) {
      hideModal();
    }
  };

  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsersList();
  }, [_id, nodeId]);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
  }, []);

  const fetchUsersList = async () => {
    try {
      const response = await subscription.getUsers({
        // currentNode: 2,
        // ruleId: "651befaa435802139013a282",
        // companyId: "w78PNmPt",

        currentNode: nodeId,
        ruleId: _id,
        companyId: getAuthDetails().companyId,
      });
      if (response.status === 200 || response.status === 201) {
        const users = response.data.data;
        setUsers(users);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="popup-modal" ref={modalRef}>
      <table>
        <thead>
          <tr>
            {TABLE_HEAD.map((it) => (
              <th key={`${it}`}>{it}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {users.map((it) => (
            <tr key={`${it.mobile}`}>
              {/* <td>{it.name}</td> */}
              <td>{it.firstName ?? "-"}</td>
              <td>{it.lastName ?? "-"}</td>
              <td>{it.mobile ?? "-"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const ControlComponent = (props) => {
  const { controls, bindControl, outputs, bindSocket } = props;

  // return (
  //   <>
  //     {controls.map((control) => (
  //       <Control className="control" key={control.key} control={control} innerRef={bindControl} />
  //     ))}
  //   </>
  // );

  let typeValue = findTypeKey(controls);

  let typeControl = controls.find((control) => control?.key === INPUT_ID_CONSTANTS?.type);

  if (!typeValue) {
    return <Control className="control" key={typeControl.key} control={typeControl} innerRef={bindControl} />;
  } else if (typeValue === TYPE_CONSTANTS.action) {
    return (
      <>
        {controls.map((control) =>
          control.key === INPUT_ID_CONSTANTS.type ||
          control.key === INPUT_ID_CONSTANTS.actionDetail ||
          control.key === INPUT_ID_CONSTANTS.actionType ? (
            <React.Fragment key={`${control.key}`}>
              <Control className="control" key={control.key} control={control} innerRef={bindControl} />
            </React.Fragment>
          ) : null
        )}
      </>
    );
  } else if (typeValue === TYPE_CONSTANTS.process) {
    return (
      <>
        {controls.map((control) =>
          control.key !== INPUT_ID_CONSTANTS.actionDetail &&
          control.key !== INPUT_ID_CONSTANTS.actionType &&
          control.key !== INPUT_ID_CONSTANTS.delayTime ? (
            <React.Fragment key={`${control.key}`}>
              {control.props.condtionIndex ? (
                <>
                  <fieldset>
                    <legend>Condition {control.props.condtionIndex}</legend>
                  </fieldset>

                  {outputs.map((output) =>
                    output.name === `condition ${control.props.condtionIndex}` ? (
                      <div className="output-socket-container" key={`${output.socket}`}>
                        <Socket type="output" socket={output.socket} io={output} innerRef={bindSocket} />
                      </div>
                    ) : (
                      false
                    )
                  )}
                </>
              ) : null}
              <Control className="control" key={control.key} control={control} innerRef={bindControl} />
            </React.Fragment>
          ) : null
        )}
      </>
    );
  } else if (typeValue === TYPE_CONSTANTS.delay) {
    return (
      <>
        {controls.map((control) =>
          control.key === INPUT_ID_CONSTANTS.type || control.key === INPUT_ID_CONSTANTS.delayTime || control.key === INPUT_ID_CONSTANTS.delayUnit ? (
            <React.Fragment key={`${control.key}`}>
              <Control className="control" key={control.key} control={control} innerRef={bindControl} />
            </React.Fragment>
          ) : null
        )}
      </>
    );
  }
};

// TYPE -> DROPDOWN -> ACTION/PROCESS
// PROCESS -> CURRENT FORM
// ACTION -> ACTION TYPE & ACTION DETAILS
