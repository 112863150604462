const getAuthDetails = () => {
  return localStorage.getItem("authDetails") ? JSON.parse(localStorage.getItem("authDetails")) : null;
};

const setupAxios = (axios) => {
  axios.defaults.headers.Accept = "application/json";

  // axios.interceptors.request.use(
  //   (config) => {
  //     const auth = AUTH_DETAILS;
  //     console.log(auth);
  //     if (auth && auth?.token) {
  //       console.log("inside", `Bearer ${auth?.token}`);
  //       config.headers.Authorization = `Bearer ${auth?.token}`;
  //     }

  //     return config;
  //   },
  //   (err) => {
  //     Promise.reject(err);
  //   }
  // );

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        let AUTH_DETAILS = getAuthDetails();
        if (AUTH_DETAILS) {
          localStorage.removeItem(AUTH_DETAILS);
          window.location.reload();
        }
      }
      return Promise.reject(error);
    }
  );
};

export { setupAxios, getAuthDetails };
