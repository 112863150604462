import { useEffect, useState } from "react";
import Form from "./pages/Form";
import Editor from "./pages/Editor";
import { setupAxios, getAuthDetails } from "./service/helper.service";
import axios from "axios";
import { STEPS_CONSTANTS } from "./constants";
import JourneyList from "./pages/JourneyList";
import { subscription } from "./service/subscription.service";
import { addToLocalStorage } from "./utils/helper";

function App() {
  setupAxios(axios);

  let initialStep = getAuthDetails()?.companyId ? localStorage.getItem("step") ?? STEPS_CONSTANTS.STEP1 : STEPS_CONSTANTS.STEP1;
  const [step, setStep] = useState(initialStep);
  const [journeyDetail, setJourneyDetail] = useState(null);

  let editId = localStorage.getItem("editId") ? JSON.parse(localStorage.getItem("editId")) : null;

  const handleStepChange = (step, id) => {
    setStep(step);
    localStorage.setItem("step", step);

    if (step === STEPS_CONSTANTS.STEP3 && id) {
      fetchJourneyJson(id);
    }
  };

  const fetchJourneyJson = async (id) => {
    addToLocalStorage("editId", id);

    try {
      const res = await subscription.getNodes(id);
      setJourneyDetail(res.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (editId) {
      fetchJourneyJson(editId);
    }
  }, [editId]);

  const getStepComponent = (step) => {
    switch (step) {
      case STEPS_CONSTANTS.STEP1:
        return <Form handleStepChange={handleStepChange} />;
      case STEPS_CONSTANTS.STEP2:
        return <JourneyList handleStepChange={handleStepChange} />;
      case STEPS_CONSTANTS.STEP3:
        return <Editor journeyDetail={journeyDetail} handleStepChange={handleStepChange} />;
    }
  };

  return (
    <div className="App" key={journeyDetail}>
      {getStepComponent(step)}
    </div>
  );
}

export default App;
