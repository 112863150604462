import React from "react";
import { createPortal } from "react-dom";

export default function Modal(props) {
  return createPortal(<ModalContainer {...props}>{props.children}</ModalContainer>, document.getElementById("root-modal"));
}

const ModalContainer = (props) => {
  const { hideCross, onHide, children, heading } = props;
  return (
    <div className="modal-container">
      <div className="modal-container-header">
        <span>{heading}</span> {hideCross ? null : <button onClick={onHide}>x</button>}
      </div>
      <div className="modal-container-body">{children}</div>
    </div>
  );
};
