import { GET_JOURNEY, GET_NODES, GET_USERS, SAVE_NODE, SUBSCRIPTION } from "./constants";
import axios from "axios";
import { getAuthDetails } from "./helper.service";

export const subscription = {
  async subscribe(params) {
    try {
      const result = await axios.post(SUBSCRIPTION, params);
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async saveNode(params) {
    try {
      const result = await axios.post(SAVE_NODE, params);
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getNodes(id) {
    let endPoint = GET_NODES + (id ? `/${id}` : "/");

    try {
      const result = await axios.get(endPoint, {
        headers: {
          companyId: getAuthDetails()?.companyId,
        },
      });
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getUsers(payload) {
    try {
      const result = await axios.post(GET_USERS, payload);
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getJourney(payload) {
    try {
      const result = await axios.post(GET_JOURNEY, payload);
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
};
