import React, { useEffect, useState } from "react";
import Modal from "../components/Modal";
import { STEPS_CONSTANTS } from "../constants";
import { subscription } from "../service/subscription.service";

const JourneyList = ({ handleStepChange }) => {
  const [nodes, setNodes] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);

  const getJourney = async () => {
    try {
      const res = await subscription.getNodes();
      setNodes(res.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getJourney();
  }, []);

  const onHandleSelect = (value) => {
    setSelectedNode(value);
  };

  const editClickHandler = () => {
    handleStepChange(STEPS_CONSTANTS.STEP3, selectedNode);
  };

  const addClickHandler = () => {
    handleStepChange(STEPS_CONSTANTS.STEP3);
  };

  return (
    <Modal hideCross={true} heading={"Select a journey to edit"}>
      <div className="step2-container">
        <div>
          <Select nodes={nodes} onHandleSelect={onHandleSelect} />
          <button style={{ marginLeft: "10px" }} onClick={editClickHandler}>
            Edit
          </button>
        </div>
        <button style={{ marginTop: "10px" }} onClick={addClickHandler}>
          Add
        </button>
      </div>
    </Modal>
  );
};

const Select = (props) => {
  const { nodes, onHandleSelect } = props;

  return (
    <>
      <select
        defaultValue={""}
        name="journey"
        id="journey"
        onChange={(e) => {
          onHandleSelect(e.target.value);
        }}>
        <option disabled value="">
          Choose a journey
        </option>

        {nodes?.length &&
          nodes.map((node) => (
            <option key={node._id} value={node._id}>
              {node.ruleName}
            </option>
          ))}
      </select>
    </>
  );
};

export default JourneyList;
