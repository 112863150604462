export const INPUT_ID_CONSTANTS = {
  label: "label",
  type: "type",
  eventName: "eventName",
  fact1: "fact_1",
  value1: "value_1",
  operator1: "operator_1",
  sum1: "sum_1",
  count1: "count_1",
  fact2: "fact_2",
  value2: "value_2",
  sum2: "sum_2",
  count2: "count_2",
  operator2: "operator_2",
  actionType: "actionType",
  actionDetail: "actionDetail",
  delayTime: "delayTime",
  delayUnit: "delayUnit",
};

export const INPUT_LABEL_CONSTANTS = {
  label: "Label",
  operator: "Operator",
  type: "Type",
  eventName: "Event Name",
  fact: "Fact",
  value: "Value",
  actionType: "Action Type",
  actionDetail: "Action Detail",
};

export const TYPE_OPTIONS_CONSTANTS = {
  action: "action",
  process: "process",
  delay: "delay",
};

export const DELAY_TIME_OPTIONS = {
  hour: "hour",
  minute: "minute",
  second: "second",
};

export const TYPE_CONSTANTS = {
  none: 0,
  action: 1,
  process: 2,
  delay: 3,
};

export const STEPS_CONSTANTS = {
  STEP1: "STEP1",
  STEP2: "STEP2",
  STEP3: "STEP3",
};
