import React, { useState } from "react";
import { subscription } from "../service/subscription.service";
import UnifyndImage from "../assets/uni.jpeg";
import { STEPS_CONSTANTS } from "../constants";
import { addToLocalStorage } from "../utils/helper";

const FORM_DATA = {
  companyName: "",
  companyType: "",
};

const Form = ({ handleStepChange }) => {
  const [formData, changeFormData] = useState(FORM_DATA);

  const handleInputChange = (type, value) => {
    changeFormData({ ...formData, [type]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData?.companyName || !formData?.companyType) {
      alert("Both fields are required");
      return;
    }

    try {
      let res = await subscription.subscribe(formData);
      addToLocalStorage("authDetails", res.data);

      handleStepChange(STEPS_CONSTANTS.STEP2);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="form-container">
      <div className="left">
        <img src={UnifyndImage} alt="" />
      </div>

      <div className="right">
        <form className="form" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Company Name"
            value={formData?.companyName}
            onChange={(e) => {
              handleInputChange("companyName", e.target.value);
            }}
          />
          <input
            type="text"
            placeholder="Comapny Type"
            value={formData?.companyType}
            onChange={(e) => {
              handleInputChange("companyType", e.target.value);
            }}
          />

          <button type="submit">Next</button>
        </form>
      </div>
    </div>
  );
};

export default Form;
