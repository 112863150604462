import { useState } from "react";
import Modal from "../components/Modal";
import { useRete } from "../components/rete";
import { getAuthDetails } from "../service/helper.service";
import { subscription } from "../service/subscription.service";
import DownArrow from "../assets/down-arrow.svg";

const validate = {
  mobile(value) {
    if (!value) return "Mobile is required";
    if (value && isNaN(value)) return "Mobile is not a number";
    if (value && value.length != 10) return "Mobile length should be 10 digits";
    else return false;
  },
};

function Editor(props) {
  const { journeyDetail } = props;

  let reteJSON = {
    id: "node-editor-rete@0.1.0",
    nodes: journeyDetail?.ruleDetailsRaw ? JSON.parse(journeyDetail?.ruleDetailsRaw) : null,
  };

  const [container, setContainer, editorRef] = useRete(reteJSON); // pass json to useRete to load
  const [showUserModal, setShowUserModal] = useState(false);
  const [name, changeName] = useState(journeyDetail?.ruleName);
  const [mobile, setMobile] = useState("");

  const [journey, setJourney] = useState(null);

  const getJson = () => {
    return editorRef.current.toJSON();
  };

  const saveJson = async () => {
    if (!name.trim()) alert("Please enter rule name");
    try {
      let json = getJson();
      if (!json) {
        throw new Error("Cannot get json");
      }

      let companyId = getAuthDetails()?.companyId;

      let postData = { ruleName: name, companyId: companyId, ruleDetails: json?.nodes };
      const response = await subscription.saveNode(postData);

      if (response.status === 200 || response.status === 201) {
        localStorage.setItem("_id", JSON.stringify(response.data.data._id));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getUserJourney = async () => {
    if (validate.mobile(mobile)) {
      alert(validate.mobile(mobile));
      return;
    }

    let ruleId = localStorage.getItem("_id") && localStorage.getItem("_id") !== "undefined" ? JSON.parse(localStorage.getItem("_id")) : null;

    try {
      const res = await subscription.getJourney({
        mobileNumber: +mobile,
        ruleId: ruleId,
        companyId: getAuthDetails()?.companyId,
      });
      if (res.status === 200 || res.statusText === "OK") {
        setShowUserModal(!showUserModal);
        setJourney(res.data);
      }
      if (res.status === 422) {
        alert(res?.message);
      }
    } catch (e) {
      console.log(e);
      if (e.response.data.status.code === 422) {
        alert(e.response.data.status.message);
      }
    }
  };

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <>
      <div className="editor-screen-header">
        <p>
          <u> Note: Right click in below area to open context menu </u>
        </p>
        <div style={{ display: "flex" }}>
          <div className="get-journey">
            <input
              type="text"
              value={mobile}
              onChange={(e) => {
                setMobile(e.target.value);
              }}
              placeholder="Enter mobile no."
              maxLength={10}
              minLength={10}
            />
            <button onClick={getUserJourney}>Get User Journey</button>
          </div>

          <div className="get-journey" style={{ marginRight: "4px" }}>
            <input
              type="text"
              value={name}
              onChange={(e) => {
                changeName(e.target.value);
              }}
              placeholder="Enter rule name"
            />
          </div>

          <button className="get-json-btn" onClick={saveJson}>
            Save Json
          </button>
        </div>
      </div>

      {!!showUserModal && (
        <Modal
          onHide={() => {
            setShowUserModal(false);
          }}>
          <div style={{ padding: "20px 0" }}>
            {journey.length &&
              journey.map((it, index) => <JourneyComponent name={it.label} eventName={it.eventName} isLast={index === journey.length - 1} />)}
          </div>
        </Modal>
      )}

      <div
        style={{
          border: "1px solid #e3e3e3",
          height: "80vh",
          maxHeight: "85vh",
          overflow: "hidden",
          maxWidth: "95%",
          margin: "0 auto",
        }}
        ref={(ref) => ref && setContainer(ref)}
      />

      <button className="logout get-json-btn" style={{ float: "right" }} onClick={logout}>
        Logout
      </button>
    </>
  );
}

const JourneyComponent = (props) => {
  const { isLast, name, eventName } = props;

  return (
    <div className="journey-card">
      <div className="journey-name">
        {name}
        <br />

        <span> Event Name: {eventName}</span>
      </div>

      <img style={{ display: isLast ? "none" : "block" }} height={30} src={DownArrow} alt="" />
    </div>
  );
};

export default Editor;
